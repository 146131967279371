/* Politique.css */

/* Réinitialisation des styles pour toutes les balises HTML */
.contenu-politique {
    font-family: sans-serif;
    line-height: 1.5;
    color: initial; /* Réinitialise la couleur du texte à sa valeur par défaut */
}

.contenu-politique p,
.contenu-politique h1,
.contenu-politique h2,
.contenu-politique h3,
.contenu-politique h4,
.contenu-politique h5,
.contenu-politique h6 {
    margin-top: 0;
    font-size: 1rem;
    font-weight: normal; /* Réinitialise le poids de la police à normal */
}

.contenu-politique h1 {
    font-size: 2rem;
    font-weight: bold; /* Réinitialise le poids de la police pour <h1> */
}

.contenu-politique ol {
    padding-left: 1.25rem;
    margin-top: 0;
    /* margin-bottom: 1rem; */
}


.contenu-politique a {
    color: inherit; /* Réinitialise la couleur des liens à la valeur par défaut */
    text-decoration: underline; /* Réinitialise la décoration de texte pour les liens */
}

/* Réinitialisation des styles pour les balises <strong> et <em> */
.contenu-politique strong,
.contenu-politique em {
    font-weight: bold;
    font-style: italic;
}

/* Réinitialisation des styles pour les balises <blockquote> */
.contenu-politique blockquote {
    margin: 0;
}

/* Réinitialisation des styles pour les balises <hr> */
.contenu-politique hr {
    /* margin-top: 1rem; */
    /* margin-bottom: 1rem; */
    border: 0;
    border-top: 1px solid #ccc;
}
.contenu-politique ul {
    margin-top: 0;
    /* margin-bottom: 1rem; */
    margin-left: 50px;
    padding-left: 1.25rem;
    list-style-type: disc;
}

/* Réinitialisation des styles pour les balises <li> */
.contenu-politique li {
    /* margin-bottom: 0.25rem; */
}
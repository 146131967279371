.contenu-badge {
    font-family: sans-serif;
    line-height: 1.5;
    color: initial; /* Réinitialise la couleur du texte à sa valeur par défaut */
}

.contenu-badge p,
.contenu-badge h1,
.contenu-badge h2,
.contenu-badge h3,
.contenu-badge h4,
.contenu-badge h5,
.contenu-badge h6 {
    margin-top: 0;
    font-size: 1rem;
    font-weight: normal; /* Réinitialise le poids de la police à normal */
}

.contenu-badge h1 {
    font-size: 2rem;
    font-weight: bold; /* Réinitialise le poids de la police pour <h1> */
}

.contenu-badge ol {
    padding-left: 1.25rem;
    margin-top: 0;
}


.contenu-badge a {
    color: inherit; /* Réinitialise la couleur des liens à la valeur par défaut */
    text-decoration: underline; /* Réinitialise la décoration de texte pour les liens */
}

/* Réinitialisation des styles pour les balises <strong> et <em> */
.contenu-badge strong,
.contenu-badge em {
    font-weight: bold;
    font-style: italic;
}

/* Réinitialisation des styles pour les balises <blockquote> */
.contenu-badge blockquote {
    margin: 0;
}

/* Réinitialisation des styles pour les balises <hr> */
.contenu-badge hr {
    margin-top: 1rem;
    border: 0;
    border-top: 1px solid #ccc;
}
.contenu-badge ul {
    margin-top: 0;
    margin-left: 50px;
    padding-left: 1.25rem;
    list-style-type: disc;
}

/* Réinitialisation des styles pour les balises <li> */
.contenu-badge li {
    margin-bottom: 0.25rem;
}
html, body {
    margin: 0;
    padding: 0;
  }
  
  .centered-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* 100% of the viewport height */
    margin: 0; /* Remove margin */
    overflow: hidden; /* Hide any content overflowing the container */
  }
  
  .centered-image {
    width: 100%; /* Make the image take up the full width of the container */
    height: 100%; /* Make the image take up the full height of the container */
    object-fit: cover; /* Maintain aspect ratio while covering the entire container */
  }